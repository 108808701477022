import React, { ReactNode, createContext, useContext, useState } from 'react';

export interface FilterState {
	filter: {
		author: string;
		category: string;
		tag: string;
		text: string;
	};
	data: {
		categoriesList: Array<{ id: string; name: string }>;
		tagsList: Array<{ id: string; name: string }>;
		authorsList: Array<{ id: string; name: string }>;
	};
	total: number;
}
interface FilterContextProps {
	filterState: FilterState;
	setFilterState: React.Dispatch<React.SetStateAction<FilterState>>;
}
interface FilterProviderProps<T extends ReactNode> {
	children: T;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export const useFilter = (): FilterContextProps => {
	const context = useContext(FilterContext);
	if (context === undefined) {
		throw new Error('useFilter must be used within a FilterProvider');
	}
	return context;
};

export const FilterProvider = <T extends ReactNode>({
	children,
}: FilterProviderProps<T>): JSX.Element => {
	const [filterState, setFilterState] = useState<FilterState>({
		filter: {
			author: '',
			category: '',
			tag: '',
			text: '',
		},
		data: {
			categoriesList: [],
			tagsList: [],
			authorsList: [],
		},
		total: 0,
	});

	return (
		<FilterContext.Provider value={{ filterState, setFilterState }}>
			{children}
		</FilterContext.Provider>
	);
};
